import { Button } from '@chakra-ui/react';
import {
  HeaderContainer,
  HeaderRowContainer,
  HeaderTitle,
  ScreenContainer,
  SectionCard,
  SectionContainer,
  SectionTitle,
  Textarea,
  TextInput,
} from '@maestro/components';
import { FieldDefinition, StoryFactory } from '@maestro/graphql';
import { dimensions, textStyles } from '@maestro/styles';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddFieldButton } from './AddFieldButton';
import { FieldsList } from './FieldsList';
import { InterpolatedText } from './InterpolatedText';

type Props = {
  formTitle: string;
  isSaving?: boolean;
  title?: string;
  prompt?: string;
  systemPrompt?: string;
  jsonSchema?: string;
  fields?: FieldDefinition[];
  onSave: (
    value: Pick<
      StoryFactory,
      'prompt' | 'fields' | 'title' | 'jsonSchema' | 'systemPrompt'
    >,
  ) => void;
  onCancel: () => void;
};

export const StoryFactoryForm: React.FC<Props> = (props) => {
  const [title, setTitle] = useState('');
  const [prompt, setPrompt] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [jsonSchema, setJsonSchema] = useState('');
  const [fields, setFields] = useState<FieldDefinition[]>([]);
  const [isDirty, setDirty] = useState(false);

  useEffect(() => {
    setTitle(props.title ?? '');
    setDirty(false);
  }, [props.title]);

  useEffect(() => {
    setPrompt(props.prompt ?? '');
    setDirty(false);
  }, [props.prompt]);

  useEffect(() => {
    setSystemPrompt(props.systemPrompt ?? '');
    setDirty(false);
  }, [props.systemPrompt]);

  useEffect(() => {
    setJsonSchema(props.jsonSchema ?? '');
    setDirty(false);
  }, [props.jsonSchema]);

  useEffect(() => {
    setFields(props.fields ?? []);
    setDirty(false);
  }, [props.fields]);

  const onFieldAdded = (field: FieldDefinition) => {
    setFields([...fields, field]);
    setDirty(true);
  };

  const onFieldRemoved = (field: FieldDefinition) => {
    setFields((fields) => fields.filter(({ id }) => id !== field.id));
    setDirty(true);
  };

  const onFieldChanged = (field: FieldDefinition, index: number) => {
    setFields((fields) => fields.map((f, idx) => (index === idx ? field : f)));
    setDirty(true);
  };

  const onPromptChanged = (prompt: string) => {
    setPrompt(prompt);
    setDirty(true);
  };

  const onSystemPromptChanged = (systemPrompt: string) => {
    setSystemPrompt(systemPrompt);
    setDirty(true);
  };

  const onTitleChanged = (title: string) => {
    setTitle(title);
    setDirty(true);
  };

  const onJsonSchemaChanged = (jsonSchema: string) => {
    setJsonSchema(jsonSchema);
    setDirty(true);
  };

  const onSave = () => {
    props.onSave({ prompt, fields, title, jsonSchema, systemPrompt });
    setDirty(false);
  };

  return (
    <ScreenContainer>
      <HeaderContainer>
        <StyledHeaderRowContainer>
          <HeaderTitle>{props.formTitle}</HeaderTitle>
          <Divider />
          <Button variant="default" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            isLoading={props.isSaving}
            onClick={onSave}
            isDisabled={!isDirty}
          >
            Save
          </Button>
        </StyledHeaderRowContainer>
      </HeaderContainer>

      <SectionContainer>
        <SectionTitle>Title</SectionTitle>
        <SectionCard>
          <FormComponent>
            <TextInput
              value={title}
              onChange={(evt) => onTitleChanged(evt.target.value)}
            />
          </FormComponent>
        </SectionCard>
        <SectionTitle>Form Fields</SectionTitle>
        <SectionCard>
          <FormComponent>
            <FieldsList
              fields={fields}
              onRemove={onFieldRemoved}
              onChange={onFieldChanged}
            />
            <AddFieldButton onAddField={onFieldAdded} />
          </FormComponent>
        </SectionCard>
        <SectionTitle>System Prompt</SectionTitle>
        <SectionCard>
          <PromptContainer>
            <StyledTextarea
              value={systemPrompt}
              onChange={(evt) => onSystemPromptChanged(evt.target.value)}
            />
            <Prompt text={systemPrompt} fields={fields} />
          </PromptContainer>
        </SectionCard>
        <SectionTitle>Prompt</SectionTitle>
        <SectionCard>
          <PromptContainer>
            <StyledTextarea
              value={prompt}
              onChange={(evt) => onPromptChanged(evt.target.value)}
            />
            <Prompt text={prompt} fields={fields} />
          </PromptContainer>
        </SectionCard>

        <SectionTitle>JSON Schema</SectionTitle>
        <SectionCard>
          <CodeTextarea
            value={jsonSchema}
            onChange={(evt) => onJsonSchemaChanged(evt.target.value)}
          />
        </SectionCard>
      </SectionContainer>
    </ScreenContainer>
  );
};

const Divider = styled.div`
  flex: 1;
`;

const StyledHeaderRowContainer = styled(HeaderRowContainer)`
  align-items: center;
  width: 100%;
`;

const PromptContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  & > div {
    flex: 1;
  }
`;

const FormComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Prompt = styled(InterpolatedText)`
  ${textStyles.body.b16m}
  white-space: pre-line;
  padding: ${dimensions.size12};
  background: ${({ theme }) => theme.colors.base.light[30]};
  border: ${dimensions.size1} solid transparent;
  border-radius: ${dimensions.size4};
`;

const StyledTextarea = styled(Textarea)`
  ${textStyles.body.b16m}
`;

const CodeTextarea = styled(Textarea)`
  ${textStyles.body.b14m}
  font-family: Menlo, monospace;
`;
